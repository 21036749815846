import React from 'react';
import Head from 'next/head';

import { ROOT_URL } from 'src/lib/constants/env.constants';
import { dangerouslySetInnerHTML } from 'src/lib/dangerouslySetInnerHTML';

import { useAppSelector } from '@hooks/redux/useAppSelector';

import { selectSearchedAds } from '@store/search/selector';

const ItemListSchemaLd = () => {
  const ads = useAppSelector(selectSearchedAds);

  const schemaData = `{
    "@context": "https://schema.org",
    "@type": "ItemList",
    "itemListElement": [
      ${ads.map(
        (ad, index) => `{
          "@type": "ListItem",
          "position": ${index + 1},
          "url": "${ROOT_URL}${ad.adUrl}"
        }`
      )}
    ]
  }`;

  return (
    <Head>
      <script
        type="application/ld+json"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={dangerouslySetInnerHTML(schemaData)}
      />
    </Head>
  );
};

export default ItemListSchemaLd;
