import values from 'lodash/values';
import { createSelector } from 'reselect';

import { byAbcOrderNo } from 'src/lib/sort/byAbcOrderNo';
import { byName } from 'src/lib/sort/byName';

import { selectOptions } from '@store/adSave/selector';

import { groupSelector } from '../rootSelector';

import {
  GroupInitialState,
  GroupItemType,
  GroupSelectorPropsType,
} from './type';

export const selectActiveGroups = createSelector(
  groupSelector,
  (_, categoryId) => categoryId,
  (slicedState: GroupInitialState, categoryId: string | number) => {
    try {
      const selectedGroups = values(slicedState.groups[categoryId]);
      return selectedGroups
        .filter((group: GroupItemType) => group.active === 'yes')
        .sort(byAbcOrderNo);
    } catch (e) {
      return [];
    }
  }
);

export const selectGroups = createSelector(
  groupSelector,
  (_, categoryId) => categoryId,
  (slicedState: GroupInitialState, categoryId: string | number) => {
    try {
      return slicedState.groupsIds[categoryId].map(
        (id: string | number) => slicedState.groups[categoryId][id]
      );
    } catch (e) {
      return [];
    }
  }
);

export const selectGroupName = createSelector(
  groupSelector,
  (_, props) => props,
  (slicedState: GroupInitialState, props: GroupSelectorPropsType) => {
    try {
      const { categoryId, groupId } = props;

      return slicedState.groups[categoryId][groupId].name;
    } catch (e) {
      return '';
    }
  }
);

export const selectGroupPsv = createSelector(
  groupSelector,
  (_, props) => props,
  (slicedState: GroupInitialState, props: GroupSelectorPropsType) => {
    try {
      const { categoryId, groupId } = props;

      return slicedState.groups[categoryId][groupId].isPsvActive;
    } catch (e) {
      return false;
    }
  }
);

export const selectCarModels = createSelector(
  groupSelector,
  (slicedState: GroupInitialState) => {
    try {
      return values(slicedState.carModels).sort(byName);
    } catch (e) {
      return [];
    }
  }
);

export const selectCarModelName = createSelector(
  groupSelector,
  (_, props) => props,
  (slicedState: GroupInitialState, props: string) => {
    try {
      return props ? slicedState.carModels[props].name : '';
    } catch (e) {
      return '';
    }
  }
);

export const selectGroupAdClass = createSelector(
  groupSelector,
  (_, props) => props,
  (slicedState: GroupInitialState, props: GroupSelectorPropsType) => {
    try {
      const { categoryId, groupId } = props;

      return slicedState.groups[categoryId][groupId].adClass;
    } catch (e) {
      return 'basic';
    }
  }
);

export const selectJobGroups = createSelector(
  groupSelector,
  (slicedState: GroupInitialState) => {
    try {
      return values(slicedState.groups[2546]).sort(byName);
    } catch (e) {
      return [];
    }
  }
);

export const selectGroupsByKind = createSelector(
  groupSelector,
  (_, props) => props,
  (slicedState: GroupInitialState, props: GroupSelectorPropsType) => {
    try {
      const { categoryId, kind } = props;

      return values(slicedState.groups[categoryId])
        .filter(
          (g: GroupItemType) => g.active === 'yes' && (!kind || g.kind === kind)
        )
        .filter((g: GroupItemType) => g.categoryType !== 'link')
        .sort(byAbcOrderNo);
    } catch (e) {
      return [];
    }
  }
);

export const selectFilteredGroups = createSelector(
  groupSelector,
  (_, categoryId) => categoryId,
  (slicedState: GroupInitialState, categoryId: string | number) => {
    try {
      const selectedGroups = values(slicedState.filteredGroups[categoryId]);
      const allowedActiveGroups = ['yes', 'view'];
      return selectedGroups
        .filter((group: GroupItemType) =>
          allowedActiveGroups.includes(group.active)
        )
        .sort(byAbcOrderNo);
    } catch (e) {
      return [];
    }
  }
);

export const selectRegisterNumbers = createSelector(selectOptions, (group) => {
  try {
    const result = [];
    if (group.show_reg_number_real_estate_agent_id !== 'no') {
      result.push({
        key: 'regNumberRealEstateAgentId',
        value: group.show_reg_number_real_estate_agent_id,
      });
    }

    if (group.show_reg_number_hid !== 'no') {
      result.push({
        key: 'regNumberHid',
        value: group.show_reg_number_hid,
      });
    }

    if (group.show_reg_number_healthcare_license_id !== 'no') {
      result.push({
        key: 'regNumberHealthcareLicenseId',
        value: group.show_reg_number_healthcare_license_id,
      });
    }

    if (group.show_reg_number_apartments_categorization_id !== 'no') {
      result.push({
        key: 'regNumberApartmentsCategorizationId',
        value: group.show_reg_number_apartments_categorization_id,
      });
    }

    if (group.show_reg_number_pet_owner_id !== 'no') {
      result.push({
        key: 'regNumberPetOwnerId',
        value: group.show_reg_number_pet_owner_id,
      });
    }

    return result;
  } catch (e) {
    return [];
  }
});

export const selectGroupAdPerformanceTip = createSelector(
  selectOptions,
  (group) => {
    try {
      return group.adPerformanceTip;
    } catch (e) {
      return '';
    }
  }
);

export const selectIsLoading = createSelector(
  groupSelector,
  (slicedState: GroupInitialState) => {
    try {
      return slicedState.isLoading;
    } catch (e) {
      return false;
    }
  }
);
