import uniqBy from 'lodash/unionBy';
import { createSelector } from 'reselect';

import { AdSaveInitialState } from '@store/adSave/type';
import { AllSuggestionType } from '@store/classifier/type';

import {
  adSaveSelector,
  agreementSelector,
  categorySelector,
  groupSelector,
} from '../rootSelector';

export const selectActiveStep = createSelector(
  adSaveSelector,
  (slicedState: AdSaveInitialState) => slicedState.activeStep
);

export const selectSteps = createSelector(
  adSaveSelector,
  (slicedState: AdSaveInitialState) => slicedState.steps
);

export const selectInitialData = createSelector(
  adSaveSelector,
  (slicedState: AdSaveInitialState) => slicedState.initialData
);

export const selectInitialDataFetched = createSelector(
  adSaveSelector,
  (slicedState: AdSaveInitialState) => slicedState.isInitialDataFetched
);

export const selectRecentGroups = createSelector(
  adSaveSelector,
  (slicedState: AdSaveInitialState) => slicedState.recentGroups
);

export const selectRecentGroupsByParentId = createSelector(
  adSaveSelector,
  (slicedState: AdSaveInitialState) => {
    try {
      return slicedState.recentGroups.filter(
        (group: AllSuggestionType) => group.parentId === slicedState.parentId
      );
    } catch (e) {
      return [];
    }
  }
);

export const selectRecentCategories = createSelector(
  adSaveSelector,
  (slicedState: AdSaveInitialState) => {
    try {
      const recentCategories = slicedState.recentGroups.map(
        (group: AllSuggestionType) => {
          return {
            id: group.parentId,
            name: group.parentName,
          };
        }
      );
      return uniqBy(recentCategories, 'id');
    } catch (e) {
      return [];
    }
  }
);

export const selectAdSaveErrors = createSelector(
  adSaveSelector,
  (slicedState: AdSaveInitialState) => slicedState.errors
);

export const selectAdSaveUserErrors = createSelector(
  adSaveSelector,
  (slicedState: AdSaveInitialState) => slicedState.userErrors
);

export const selectIsStepNextLoading = createSelector(
  adSaveSelector,
  (slicedState: AdSaveInitialState) => slicedState.isStepNextLoading
);

export const selectOptions = createSelector(
  adSaveSelector,
  categorySelector,
  groupSelector,
  (slicedState: AdSaveInitialState, categoryState, groupState) => {
    try {
      const { id, parentId } = slicedState;

      if (parentId && id && groupState.groups[parentId]) {
        return groupState.groups[parentId][id] ?? slicedState.options;
      }

      if (parentId && categoryState.categories[parentId]) {
        return categoryState.categories[parentId] ?? slicedState.options;
      }
      return slicedState.options;
    } catch (e) {
      return slicedState.options;
    }
  }
);

export const selectKind = createSelector(selectOptions, (state) => {
  return state.kind;
});

export const selectAdClass = createSelector(selectOptions, (state) => {
  return state.adClass;
});

export const selectAdPhotoMax = createSelector(selectOptions, (state) => {
  return state.adPhotosMax || 15;
});

export const selectShowExchange = createSelector(selectOptions, (state) => {
  return state.showExchange === 'yes';
});

export const selectIsAdCreateServiceActive = createSelector(
  selectOptions,
  (state) => {
    return state.isPsvActive;
  }
);

export const selectShowCondition = createSelector(selectOptions, (state) => {
  return state.showCondition === 'yes';
});

export const selectShowImmediateAvailableAllowed = createSelector(
  selectOptions,
  (state) => {
    return state.isImmediateAvailableAllowed;
  }
);
export const selectShowDeliveryMethodAllowed = createSelector(
  selectOptions,
  (state) => {
    return state.isDeliveryMethodAllowed;
  }
);

export const selectIsDeclarationTypeCompany = createSelector(
  selectOptions,
  (state) => {
    return state.declarationType === 'company';
  }
);

export const selectAgreements = createSelector(
  selectOptions,
  agreementSelector,
  (state, agreements) => {
    try {
      const { agreementIds } = state;

      return agreementIds.map((id) =>
        agreements.list.find((agreement) => agreement.id === id)
      );
    } catch (e) {
      return [];
    }
  }
);

export const selectCaptchaInfo = createSelector(
  adSaveSelector,
  (slicedState: AdSaveInitialState) => slicedState.captcha
);

export const selectPhoneDisplayControl = createSelector(
  adSaveSelector,
  (slicedState: AdSaveInitialState) => slicedState.phoneDisplayControl
);

export const selectIsEditAd = createSelector(
  adSaveSelector,
  (slicedState: AdSaveInitialState) => slicedState.isEditAd
);

export const selectUniqueStamp = createSelector(
  adSaveSelector,
  (slicedState: AdSaveInitialState) => slicedState.uniqueStamp
);

export const selectIsHeaderBackClicked = createSelector(
  adSaveSelector,
  (slicedState: AdSaveInitialState) => slicedState.isHeaderBackClicked
);

export const selectAsBackPath = createSelector(
  adSaveSelector,
  (slicedState: AdSaveInitialState) => slicedState.asBackPath
);

export const selectQuickEditAdId = createSelector(
  adSaveSelector,
  (slicedState: AdSaveInitialState) => slicedState.quickEditAdId
);

export const selectIsQuickEditLoading = createSelector(
  adSaveSelector,
  (slicedState: AdSaveInitialState) => slicedState.isQuickEditLoading
);

export const selectImmediateAvailableInfo = createSelector(
  adSaveSelector,
  (slicedState: AdSaveInitialState) => slicedState.immediateAvailableInfo
);

export const selectDeliveryMethodsInfo = createSelector(
  adSaveSelector,
  (slicedState: AdSaveInitialState) => slicedState.deliveryMethodsInfo
);

export const selectIsIsbnScanSupported = createSelector(
  adSaveSelector,
  (slicedState: AdSaveInitialState) => slicedState.isIsbnScanSupported
);

export const selectIsPromoItemRequired = createSelector(
  adSaveSelector,
  (slicedState: AdSaveInitialState) => slicedState.isPromotionItemRequired
);

export const selectIsGroupInfoFetched = createSelector(
  adSaveSelector,
  (slicedState: AdSaveInitialState) => slicedState.isGroupInfoFetched
);

export const selectIsStepBack = createSelector(
  adSaveSelector,
  (slicedState: AdSaveInitialState) => slicedState.isStepBack
);

export const selectGroupAdPerformanceTip = createSelector(
  selectOptions,
  (state) => {
    try {
      return state.adPerformanceTip;
    } catch (e) {
      return '';
    }
  }
);

export const selectShowErrorLinkFields = createSelector(
  adSaveSelector,
  (slicedState: AdSaveInitialState) => slicedState.showErrorLinkFields
);
