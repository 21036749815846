import { createSelector } from 'reselect';

import { userSelector } from '../rootSelector';

import { User } from './model';
import { UserInitialState } from './type';

export const selectUserSummary = createSelector(
  userSelector,
  (slicedState: UserInitialState) => {
    try {
      return slicedState.summary;
    } catch (e) {
      return {} as User;
    }
  }
);

export const selectIsLoading = createSelector(
  userSelector,
  (slicedState: UserInitialState) => slicedState.isLoading
);

export const selectRegisterErrors = createSelector(
  userSelector,
  (slicedState: UserInitialState) => slicedState.register.errors
);

export const selectLoading = createSelector(
  userSelector,
  (slicedState: UserInitialState) => slicedState.loading
);

export const selectUserPredefinedCategories = createSelector(
  userSelector,
  (slicedState: UserInitialState) => slicedState.summaryAds?.filteredCategories
);

export const selectUserPredefinedGroups = createSelector(
  userSelector,
  (_, categoryId) => categoryId,
  (slicedState: UserInitialState, categoryId: string | number) => {
    try {
      return slicedState.summaryAds?.filteredGroups.filter(
        (group) => group?.parent === categoryId
      );
    } catch (e) {
      return [];
    }
  }
);

export const selectUserPredefinedLocation = createSelector(
  userSelector,
  (slicedState: UserInitialState) => slicedState.summaryAds?.filteredLocations
);
