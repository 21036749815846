import isEmpty from 'lodash/isEmpty';
import values from 'lodash/values';
import { createSelector } from 'reselect';

import { byAbcOrderNo } from 'src/lib/sort/byAbcOrderNo';

import { GroupInitialState } from '@store/group/type';

import { categorySelector, groupSelector } from '../rootSelector';

import { CategoryInitialState, CategoryItemType } from './type';

export const selectCategoriesByName = createSelector(
  categorySelector,
  (slicedState: CategoryInitialState) => {
    try {
      return values(slicedState.categories).sort(byAbcOrderNo);
    } catch (e) {
      return [];
    }
  }
);

export const selectActiveCategoriesByName = createSelector(
  categorySelector,
  (slicedState: CategoryInitialState) => {
    try {
      return values(slicedState.categories)
        .filter((category: CategoryItemType) => category.active === 'yes')
        .sort(byAbcOrderNo);
    } catch (e) {
      return [];
    }
  }
);

export const selectGoodsCategoriesByName = createSelector(
  categorySelector,
  (slicedState: CategoryInitialState) => {
    try {
      return values(slicedState.categories)
        .filter((category: CategoryItemType) => category.kind === 'goods')
        .sort(byAbcOrderNo);
    } catch (e) {
      return [];
    }
  }
);

export const selectJobCategories = createSelector(
  categorySelector,
  (slicedState: CategoryInitialState) => {
    try {
      return values(slicedState.categories).filter(
        (category: CategoryItemType) => category.kind === 'job'
      );
    } catch (e) {
      return [];
    }
  }
);

export const selectCategories = createSelector(
  categorySelector,
  (slicedState: CategoryInitialState) => {
    try {
      return slicedState.categories;
    } catch (e) {
      return [];
    }
  }
);

export const selectIsLoading = createSelector(
  categorySelector,
  (slicedState: CategoryInitialState) => slicedState.isLoading
);

export const selectCategoryName = createSelector(
  categorySelector,
  (_, categoryId) => categoryId,
  (slicedState: CategoryInitialState, categoryId: string) => {
    try {
      return slicedState.categories[categoryId].name;
    } catch (e) {
      return '';
    }
  }
);

export const selectCategoryShowCondition = createSelector(
  categorySelector,
  (_, categoryId) => categoryId,
  (slicedState: CategoryInitialState, categoryId: string) => {
    try {
      return slicedState.categories[categoryId].showCondition === 'yes';
    } catch (e) {
      return true;
    }
  }
);

export const selectCategoryKind = createSelector(
  categorySelector,
  (_, categoryId) => categoryId,
  (slicedState: CategoryInitialState, categoryId: string) => {
    try {
      return slicedState.categories[categoryId].kind;
    } catch (e) {
      return '';
    }
  }
);

export const selectCategoryAdClass = createSelector(
  categorySelector,
  (_, categoryId) => categoryId,
  (slicedState: CategoryInitialState, categoryId: string) => {
    try {
      return slicedState.categories[categoryId].adClass;
    } catch (e) {
      return 'basic';
    }
  }
);

export const selectCategoriesByKind = createSelector(
  categorySelector,
  (_, kind) => kind,
  (slicedState: CategoryInitialState, kind: string) => {
    try {
      return values(slicedState.categories)
        .filter(
          (category: CategoryItemType) =>
            category.active === 'yes' && (!kind || category.kind === kind)
        )
        .sort(byAbcOrderNo);
    } catch (e) {
      return [];
    }
  }
);

export const selectFilteredCategoriesByName = createSelector(
  categorySelector,
  (slicedState: CategoryInitialState) => {
    try {
      return values(slicedState.filteredCategories)
        .filter((category: CategoryItemType) => category.active === 'yes')
        .sort(byAbcOrderNo);
    } catch (e) {
      return [];
    }
  }
);

export const selectSearchPlaceholder = createSelector(
  categorySelector,
  groupSelector,
  (_, props) => props,
  (
    slicedState: CategoryInitialState,
    groupState: GroupInitialState,
    props: { categoryId: string; groupId: string }
  ) => {
    try {
      const { categoryId, groupId } = props;
      const groupSearchPlaceholder =
        groupState?.groups?.[categoryId]?.[groupId]?.searchPlaceholder;

      if (!isEmpty(groupSearchPlaceholder)) return groupSearchPlaceholder;

      const categorySearchPlaceholder =
        slicedState?.categories?.[categoryId]?.searchPlaceholder;

      if (!isEmpty(categorySearchPlaceholder)) return categorySearchPlaceholder;

      return '';
    } catch (e) {
      return '';
    }
  }
);
